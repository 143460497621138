<template>
  <div>
    <!-- <headers /> -->
    <div class="ssstit">
      <div>{{ datalist.title }}</div>
      <div class="cc1">{{ datalist.updated_at.split(" ")[0] }}</div>
    </div>
    <div v-html="datalist.content" style="padding: 0% 2%"></div>
    <!-- <txts :html="datalist" /> -->
    <page-footer />
  </div>
</template>

<script>
export default {
  data() {
    return {
      ids: "",
      datalist: [],
    };
  },
  created() {
    this.ids = this.$route.query.id;
    console.log(this.ids);
    this.getNewsdetail(this.ids);
  },
  methods: {
    getNewsdetail() {
      var self = this;
      this.axios({
        method: "post",
        url: "https://www.zhixiaozi.com/square/api/v1/article/info",
        data: { id: this.ids },
      }).then(function (resp) {
        console.log(resp);
        self.datalist = resp.data.data;
      });
    },
  },
  components: {
    "page-footer": () => import("@/components/footer.vue"),
    headers: () => import("@/components/header.vue"),
  },
};
</script>

<style scoped>
.ssstit {
  padding: 4% 0%;
  opacity: 1;
  font-size: 2em;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  text-align: left;
  color: #333333;
  width: 90%;
  margin: 0 auto;
  border-bottom: 1px dashed #dce1e3;
  margin-bottom: 5%;
}
.cc1 {
  opacity: 1;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  color: #bfc9cc;
  margin-top: 2%;
}
.vote {
  padding: 20px;
  background-color: #f8f8f8;
  text-align: left;
}
.vote-head {
  border-bottom: 2px dashed #969798;
  padding-bottom: 20px;
}
.vote-head div {
  margin-top: 10px;
  color: #999999;
  font-size: 12px;
}
.vote-head div span:first-child {
  margin-right: 10px;
}
.tips {
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #666;
}
.vote-data {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.vote-data-item {
  width: 50%;
}
.vote-data-item:nth-child(2n + 2) {
  margin-right: 0;
}
.vote-data-item-wrap {
  background-color: #fff;
  padding-bottom: 10px;
  margin-bottom: 10px;
  padding: 5px;
}
.vote-data-item:nth-child(2n + 1) .vote-data-item-wrap {
  margin-right: 5px;
}
.vote-data-item:nth-child(2n + 2) .vote-data-item-wrap {
  margin-left: 5px;
}
.vote-data-item img {
  height: 200px;
  width: 100%;
}
.vote-data-item .content {
  display: flex;
  margin: auto;
  width: 100%;
  align-items: center;
}
.vote-data-item .content .rank {
  display: inline-block;
  border: 1px solid #dbdcdd;
  border-radius: 50%;
  height: 20px;
  line-height: 20px;
  width: 20px;
  text-align: center;
  color: #666666;
  font-size: 12px;
}
.vote-data-item .rank-active {
  border: 1px solid;
  color: #fff !important;
}
.vote-data-item .content .title {
  flex: 1;
  padding: 0 10px;
  font-weight: 500;
  color: #666666;
  font-size: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.vote-data-item .content .poll {
  font-size: 12px;
  color: #999999;
}
.vote-data-item .source {
  text-align: center;
  color: #999;
  font-size: 12px;
}
.vote-container {
  padding: 20px 20px 40px 20px;
  background-color: #fff;
  text-align: left;
}
.vote-list {
  margin: 20px 0;
}
.vote-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.vote-list-item .rank div {
  border-radius: 50%;
  text-align: center;
  border: 1px solid #dbdcdd;
  height: 40px;
  line-height: 40px;
  width: 40px;
}
.vote-list-item .content {
  flex: 1;
  padding: 0 20px;
  color: #666666;
  font-size: 12px;
}
.vote-list-item .content .title {
  font-size: 14px;
  margin-bottom: 10px;
}
.vote-list-item .content .des {
  display: flex;
  align-items: center;
}
.vote-list-item .progress {
  flex: 1;
  margin: 0 10px;
  border-radius: 10px;
  border: 1px solid #dbdcdd;
  height: 10px;
  overflow: hidden;
}
.progress-wrap {
  background-color: #d9d9d9;
  width: 100%;
  height: 100%;
}
</style>
